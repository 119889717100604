@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
	font-family:'Chance';
	src:	url(/static/media/Chance-Light.5014a2d2.eot);
	src:	url(/static/media/Chance-Light.5014a2d2.eot?#iefix) format('embedded-opentype'),
			url(/static/media/Chance-Light.c6197594.woff2) format('woff2'),
			url(/static/media/Chance-Light.ee0e88ee.woff) format('woff');
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family:'Chance';
	src:	url(/static/media/Chance-Regular.201f5234.eot);
	src:	url(/static/media/Chance-Regular.201f5234.eot?#iefix) format('embedded-opentype'),
			url(/static/media/Chance-Regular.0105abac.woff2) format('woff2'),
			url(/static/media/Chance-Regular.1b5498c9.woff) format('woff');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family:'Chance';
	src:	url(/static/media/Chance-Medium.db5f4cdb.woff2) format('woff2'),
			url(/static/media/Chance-Medium.c007d157.woff) format('woff');
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family:'Chance';
	src:	url(/static/media/Chance-Bold.ed152a71.eot);
	src:	url(/static/media/Chance-Bold.ed152a71.eot?#iefix) format('embedded-opentype'),
			url(/static/media/Chance-Bold.c1f44877.woff2) format('woff2'),
			url(/static/media/Chance-Bold.b1a5567e.woff) format('woff');
	font-weight: 700;
	font-style: normal;
}
@font-face {
    font-family: 'CaslonGraphiqueEF';
    src: url(/static/media/CaslonGraphiqueEF.507023d6.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}
/** scrollbar **/
::-webkit-scrollbar {
    width: 3px;
    height: 7px;
  }
  ::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background: #d3d3d3;
    border: 0px none #ffffff;
    border-radius: 4px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #525965;
  }
  ::-webkit-scrollbar-thumb:active {
    background: #525965;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
    border: 0px none #ffffff;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track:hover {
    background: transparent;
  }
  ::-webkit-scrollbar-track:active {
    background: transparent;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
/** end scrollbar **/
.App {
    font-family: 'Chance', sans-serif;
    display: flex;
}
.SideBar{
    width:300px;
    position: fixed;
    background-color: #21409a;
    box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
    min-height: 100vh;
    
}
.SideBar .scroll ul,.configuration-content-body .row ul{
    padding: 0;
}
.configuration-content-body .row ul{
    max-height: 200px;
    overflow-y: scroll;
    padding-right: 5px;
    margin-right: -8px;
}
.SideBar .scroll .object,.configuration-content-body .object{
    display: flex;
    list-style: none;
    color:#f6f6f6;
    margin-bottom: 12px;
    justify-content: space-between;
    align-items: center;
    background-color: #01b9ff;
    font-size: 14px;
    padding-left: 16px;
}
.SideBar .scroll{
    max-height: calc(100vh - 100px);
    overflow-y: scroll;
    margin: 14px;
    margin-right: 1px;
    padding-right: 14px;
}
.border-bottom-white{
    border-bottom: 1px solid #d3d3d3;
}
.mb-1{
    margin-bottom: 1rem;
}
.mt-1{
    margin-top: 1rem;
}
.SideBar .scroll .row .marge{
    display: flex;
    justify-content: space-around;
}
.SideBar .scroll .row{
    padding-bottom: 1rem;
    margin-bottom: 1rem;
}
.SideBar .scroll .row.hide{
    display: none;
}
.SideBar .scroll .row .style,.popup .style{
    display: flex;
}
.SideBar .scroll .row .style .color .color-selector{
    width: auto;
    padding-right: 13px;
    margin-right: 5px;
}
.SideBar .scroll .row .style.deux .color,.SideBar .scroll .row .style.deux .size{
    width: 50%;
}
.SideBar .scroll .row .style .weight .select-dropdown{
    width: auto;
    margin-left: 5px;
}
.SideBar .scroll .row .style .weight .select-dropdown{
    width: 86px ;
}
.SideBar .scroll .row .style .weight .select-dropdown ul{
    width: 82px!important ;
    z-index: 11!important;
}
.popup .style{
    margin-bottom: 16px;
}
.popup .style .color{
    width: 50%;
}
.SideBar .scroll .row .style .align{
    width: 33.33%;
}
/** radio button **/
.App input[type="radio"]{
    display: none;
  }
.App input[type="radio"] + label{
    position: relative;
    display: inline-block;
    color: #f6f6f6;
    padding-left: 1.5em;
    cursor: pointer;
    line-height: 1em;
    font-size: 12px;
    transition: all 0.3s ease-in-out;
}
.App input[type="radio"] + label:before,
.App input[type="radio"] + label:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 1em;
    height: 1em;
    text-align: center;
    color: white;
    border-radius: 50%;
    transition: all .3s ease;
}
.App input[type="radio"] + label:before {
    transition: all .3s ease;
    box-shadow: inset 0 0 0 0.2em white, inset 0 0 0 1em white;
}
.App input[type="radio"] + label:hover:before {
    transition: all .3s ease;
    box-shadow: inset 0 0 0 0.3em white, inset 0 0 0 1em #c6c6c6;
}
.App input[type="radio"]:checked + label:before {
    transition: all .3s ease;
    box-shadow: inset 0 0 0 0.2em white, inset 0 0 0 1em #ef0909;
}
/* end radio */
.SideBar .scroll .row .style.three{
    align-items: flex-end;
}
.SideBar .scroll .row .style.three .size{
    width: 25%;
}
h1,p,h2,h4,h3,h5{
    color:#f6f6f6;
}
h4{
    font-size: 15px;
    font-weight: 500;
}
h5{
    font-size: 14px;
    font-weight: 500;
    margin-top: 0;
}
p{
    font-size:13px;
    margin-top: 0;
}
.SideBar .color-selector {
    display: inline-block;
    margin: 0 auto;
    position: relative;
    font-size: 14px;
    padding: .56rem .5rem;
    background: #01b9ff;
    color:#fff;
    width: 80%;
}
.SideBar .color-selector::after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 5px 0 5px;
    border-color: #fff transparent transparent transparent;
    position: absolute;
    right: 2px;
    top: 20px;
} 
.SideBar .color-selector .circle {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #eee;
    margin-right: 5px;
}  
.SideBar .color-selector .hidden {
    position: absolute;
    left: 0;
    opacity: 0;
}  
.SideBar .color-selector span {
    display: inline-block;
    vertical-align: middle;
}
/** input number **/
.SideBar .input-number {
    position: relative;
    display: flex;
    width: 52px;
    height: 40px;
    background-color: transparent;
    overflow: hidden;
    margin: 0;
  }
.SideBar .input-number span {
    background-color: #01b9ff;
    height: 100%;
    width: 30px;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    color: #fff;
}
.SideBar .input-number .action{
    display: flex;
    flex-direction: column;
}
.SideBar .input-number button {
    display: inline-block;
    width: 22px;
    height:20px;
    background-color: #ef0909;
    border: none;
    color: white;
    font-size: 18px;
    cursor: pointer;
    transition: background-color .2s ease;
}
.SideBar .input-number button:nth-of-type(1) {
    border-bottom: 1px solid rgba(0,0,0,.2);
}
/** end input number **/
/** checkbox **/
.form-group{
    margin-bottom: 16px;
}
.SideBar .form-group{
    text-align: left;
}
.form-group input {
    padding: 0;
    height: auto;
    height: initial;
    width: auto;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
} 
.form-group label {
    position: relative;
    cursor: pointer;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
}
.form-group label .square {
    -webkit-appearance: none;
    background-color: #ef0909;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
}
.form-group input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 6px;
    width: 6px;
    height: 14px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
.App .SideBar .form-group label{
    text-align: left;
}
.App .form-group label{
    text-align: justify;
    max-width: 350px;
    display: block;
    line-height: 1.5;
}
/** end checkbox **/
.dropdown{
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 10px 25px rgba(0,0,0,.1);
    background-color: white;
    text-align: left;
    display: block;
    margin-bottom: 16px;
}
a.dropdown{
    text-decoration: none;
    color:#000
}
a.dropdown.active{
    background-color: #01b9ff;
    color:#fff
}
.dropdown span.info{
    display:block;
    padding: 15px;
    cursor: pointer;
}
.dropdown-header {
    padding: 15px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.dropdown-body {
    padding: 5px;
    border: 1px solid #E5E8EC;
    display: none;
    background-color: #21409a;
    border-bottom-left-radius:10px ;
    border-bottom-right-radius:10px ;
}
.dropdown-body.open {
    display: block;
}
.icon {
    font-size: 13px;
    color: #91A5BE;
    transform: rotate(0deg);
    transition: all .2s ease-in-out;
} 
.icon.open {
    transform: rotate(90deg);
}
.Content{
    margin-left: 300px;
    width:calc(100% - 300px);
    display: block;
    padding: 1rem;
    position: relative;
    min-height: calc(100vh - 32px);
}
.App .header {
    min-height: 125px;
    text-align: center;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    box-shadow: 0px 1px rgba(0,0,0,.1);
    width: auto;
}
.App .header h1,h2{
    margin: 0;
}
.App .header .logo,.App .header .text{
    position: relative;
    z-index: 1;
}
.App .header .text{
    text-align: left;
}
.App .header .contenu{
    position: relative;
    max-width: 160px;
}
.App .login,.App .stream,.App .after,.App .before{
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
}
.App .section .contenu .losange-body{
    width: 150px;
    height: 150px;
    display: block;
    position: absolute;
    transform: rotate(45deg);
    right: 5px;
    top: -34px;
}
.App .section .contenu .text{
    text-align: center;
    position: relative;
    min-width: 160px;
}
.App .section .contenu .text .grand{
    font-size: 40px;
    margin-bottom: 0;
    font-weight: 600;
}
.App .section .contenu .text .petit{
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 3px
}
.App .section .contenu .text .heure{
    font-size: 14px;
}
.App .section{
    display: flex;
    height: 100%;
    position: relative;
}
.App .section button:disabled{
    opacity:0.5;
    cursor: not-allowed;
}
.App .Content .section .first-column,.App .Content .section .second-column{
    border:2px dashed #01b9ff;
}
.App .section .first-column.only{
    width:100%!important;
}
.App .section .second-column{
    border-left: none;
}
textarea{
    font-family: "Roboto",sans-serif;
}
input:focus,textarea:focus{
    outline:0
}
input::-webkit-input-placeholder, textarea::-webkit-input-placeholder{
    color:rgba(255,255,255, .7)
}
input:-ms-input-placeholder, textarea:-ms-input-placeholder{
    color:rgba(255,255,255, .7)
}
input::-ms-input-placeholder, textarea::-ms-input-placeholder{
    color:rgba(255,255,255, .7)
}
input::placeholder,textarea::placeholder{
    color:rgba(255,255,255, .7)
}
.custom-file-upload{
    display: block;
    cursor: pointer;
    height: 150px;
}
.custom-file-upload .img-wrap{
    display: block;
}
.custom-file-upload .img-wrap img{
    width: 100%;
    height: 150px;
    object-fit: contain;
    background-color: #01b9ff;
    box-shadow: 4px 4px rgba(0,0,0,.4);
}
.custom-file-upload input[type="file"]{
    height: 0;
    width: 0;
}
/** Custom select **/
.select-dropdown {
    color: #f6f6f6;
    position: relative;
    width: 250px;
    display: inline-block
}
.select-dropdown ul {
    max-height: 0;
    transition: max-height 0.5s;
}
.select-dropdown ul.show {
    max-height: 270px;
    color: #6f6f6f;
}
.select-dropdown ul li:hover {
    background: #EFEFEF;
}
/* en custom select */
/** style popup **/
.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0,0,0, 0.7);
    z-index: 9999;
}
.popup_inner {
    position: absolute;
    left: calc(25% + 300px);
    right: 25%;
    top: 50%;
    transform: translateY(-50%);
    margin: auto;
    background: #21409a;
    width: 450px;
    padding:0 0 16px 16px;
}
.connexion-form input{
    display: inline-block;
}
.popup_inner .popup-header{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #d3d3d3;
}
.popup_inner .popup-header h4{
    margin: 10px 0;
    font-size: 18px;
}
.popup_inner .popup-body{
    padding:16px
}
/** sidenav **/
#mySidenav{
    position: fixed;
    right: 0;
    top: 10%;
    z-index:10000
}
#mySidenav a {
    right: -60px;
    color: white;
    width: 100px;
    padding: 15px;
    position: absolute;
    transition: 0.3s;
    font-size: 16px;
    text-decoration: none;
    border-radius: 5px 0 0 5px;
    box-shadow: 0px 2px 2px rgba(0,0,0,.2);
}
#mySidenav a:hover,#mySidenav a.active {
    right:0;
}
#view{
    top: 0;
    background-color: #1a1a1a;
}
#login {
    top: 115px;
    background-color: green;
}
#before {
    top: 170px;
    background-color: #ffc107;
}
#stream {
    top: 225px;
    background-color:#21409a;
}
#after {
    top: 280px;
    background-color: #ef0909;
}
/** player vimeo */
.player{
    max-width: 850px;
    width: 100%;
}
.player .iframe-wrapper{
    position: relative;
    padding-bottom: 56.25%;
}
.player .iframe-wrapper iframe{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.popup-fullscreen {
    position: absolute;
    width: 100%;
    z-index: 1;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: #000;
    z-index: 9998;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.popup-fullscreen .player{
    max-width: 100%;
    position: absolute;
}
.popup-fullscreen .close{
    position: absolute;
    display: block;
    top: 1rem;
    cursor: pointer;
    z-index: 99;
}
.popup-fullscreen .close svg{
    fill:#fff;
    width: 25px;
}
.popup-fullscreen .fullscreenForm{
    position: relative;
    z-index:1;
    float: right;
    width: 100%;
}
.popup-fullscreen .fullscreenForm .content{
    display: flex;
    justify-content: flex-end;
}
.popup-fullscreen .fullscreenForm .content input{
    margin-bottom: 0;
    transition: width 0.3s ease;
    width: 0;
    padding-left: 0;
    padding-right: 0;
}
.popup-fullscreen .fullscreenForm.open .content input{
    padding-left: 24px;
    width: calc(100% - 200px);
}
.popup-fullscreen .fullscreenForm .content button{
    min-width: 200px;
    padding-top: .85rem;
    padding-bottom: .85rem;
}
.popup-fullscreen .fullscreenForm .content span{
    color:#fff;
    min-width: 200px;
    cursor:pointer;
    padding:.85rem 0;
    text-align: center;
    display: block;
    position: absolute;
    right: 0;
    top: 0;
}
.popup-fullscreen .fullscreenForm.open .content span{
    display: none;
}
.Content .configuration-content{
    max-width: 400px;
    background-color: #21409a;
    margin: auto;
    top: 50%;
    position: relative;
    transform: translateY(-50%);
}
.Content .configuration-content-header{
    padding: 8px;
    border-bottom: 1px solid #f6f6f6;
}
.Content .configuration-content-header h3{
    font-size: 18px;
    margin:0;
}
.Content .configuration-content-body{
    padding: 16px;
}
.Content .configuration-content-body .select-dropdown{
    width: 100%;
}
.Content .configuration-content-body .select-dropdown ul{
    width: calc(100% - 4px)!important;
}
.Content .configuration-content .note{
    font-size: 12px;
    margin-bottom: 1rem;
    color:#ffc107;
    display: block;
}
.Content .configuration-content #datetimepicker,.Content .configuration-content #datetimepickerfin{
    background-color: #01b9ff;
    padding: .5rem .75rem;
    color:#f6f6f6;
    font-size: 16px;
    font-family: 'chance',sans-serif;
}
.Content .configuration-content .e-input-group.e-control-wrapper .e-clear-icon,.Content .configuration-content .e-datetime-wrapper .e-input-group-icon.e-date-icon,.Content .configuration-content .e-datetime-wrapper .e-input-group-icon.e-time-icon{
    color:#fff;
    padding: 0 8px;
    background-color: #ef0909;
    margin: 0;
}
.Content .configuration-content .e-datetime-wrapper .e-input-group-icon.e-date-icon,.Content .configuration-content .e-input-group.e-control-wrapper .e-clear-icon{
    border-right: 1px solid rgba(0,0,0,.5);
}
.PrimaryButton{
    background-color:#ef0909;
    border:rgba(0,0,0,.3);
    padding:.5rem .75rem;
    color:#fff;
    font-size:14px;
    cursor:pointer;
    font-family:inherit;
    text-decoration: none;
}
.button-action{
    display: flex;
    justify-content: space-between;
}
/** mobile view **/
@media (max-width:767px) {
    .App .section,.App .header{
        flex-direction: column;
    }
    .App .header{
        min-height: 150px;
    }
    .App .section .first-column,.App .section .second-column{
        width: 100%!important;
        min-height: calc(100vh - 150px);
    }
    .App .section .second-column{
        border-top: none;
        border-left:2px dashed #01b9ff
    }
}
